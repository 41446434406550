<template>
  <div class="form-container" :class="containerClass">
    <label
      v-if="label"
      class="form-input-label"
      :class="[statusInput, customLabelClass, requiredInput && 'form-input-label-required']"
    >
      <slot name="label" v-bind="{ label, tooltip }">
        {{ label }}
        <HccInfoTooltip v-if="tooltip" :text="tooltip" :custom-class="'icon-info__table'" />
      </slot>
    </label>

    <div
      class="form-control"
      :class="[size, statusInput, customContainerClass, borderLess && 'form-control-border-less']"
    >
      <button
        v-if="iconInputStart"
        type="button"
        class="icon"
        tabindex="-1"
        :class="{ 'disabled': disabled }"
        :disabled="disabled"
      >
        <Icon :icon="iconInputStart" class="icon-md" />
      </button>

      <input
        ref="hccInput"
        :data-test-id="dataTestId"
        :class="[statusInput, customClass]"
        class="input"
        :type="inputType"
        :readonly="readonly"
        :disabled="disabled"
        :data-test-value="modelValue"
        :value="modelValue"
        :maxlength="counterCharacters ? counterCharacters : null"
        v-bind="$attrs"
        @input="$emit('update:modelValue', $event.target.value)"
      />

      <div v-if="textIcon" class="icon">
        <p class="text-icon">{{ textIcon }}</p>
      </div>

      <template v-for="action in actions">
        <button
          class="icon icon-action-right-icon"
          tabindex="-1"
          :class="[action.cursor, action.customClass, { 'disabled': disabled || action.disabled }]"
          :style="{ color: `var(${action.color})` }"
          :type="action.buttonType || 'button'"
          :disabled="disabled || action.disabled"
          :data-test-id=" action.dataTestId || 'actionButton'"
          @click="actionIcon(action)"
        >
        <Icon
          class="icon-md"
          :class="action.class"
          :icon="action.type === 'showPassword' && !showPassword
            ? 'mdi:eye-off'
            : action.icon"
        />
        </button>
      </template>
    </div>

    <div
      v-if="helpText || counterCharacters"
      class="form-hint"
      :class="statusInput"
    >
      <p v-if="helpText">
        {{ helpText }}
      </p>

      <div v-if="counterCharacters" class="counter-box" :class="{ 'counter-characters': counterCharacterStyle}">
        {{ modelValue.length }} / {{ counterCharacters }}
      </div>
    </div>

    <div v-if="error && hasError" class="form-input__error">
      <p
        v-for="(error, index) in hasError"
        :key="index"
        :data-test-id="`${dataTestId || 'input' }ErrorLabel`"
        >
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';
import HccInfoTooltip from '../HccInfoTooltip/HccInfoTooltip.vue';

export default {
  props: {
    iconInputStart: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'sm',
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
    },
    hasError: {
      type: [String, Object, Array],
    },
    borderLess: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    textIcon: {
      type: String,
    },
    modelValue: {
      required: true,
    },
    label: {
      type: String,
    },
    customClass: {
      type: [String, Object, Array],
    },
    customContainerClass: {
      type: [String, Object, Array],
    },
    requiredInput: {
      type: Boolean,
      default: false,
    },
    helpText: {
      type: String,
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
    dataTestId: {
      type: String,
      default: null,
    },
    actions: {
      type: Array,
      default: []
    },
    counterCharacters: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    containerClass: {
      type: [String, Array],
      default: null,
    },
    customLabelClass: {
      type: [String, Array],
      default: null,
    },
  },
  emits: ['update:modelValue'],
  components: {
    Icon,
    HccInfoTooltip,
  },
  data() {
    return {
      iconValue: '',
      inputValue: '',
      inputMethod: null,
      showPassword: true,
      inputType: this.type,
      counterCharacterStyle: '',
    };
  },
  methods: {
    focus() {
      this.$refs.hccInput.focus();
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
      this.inputType = this.showPassword ? 'password' : 'text';
    },
    actionIcon(action) {
      if (action.type === 'showPassword') {
        this.toggleShowPassword();
        return;
      }

      return action.onAction && action.onAction();
    }
  },
  watch: {
    modelValue(newVal) {
      if (this.counterCharacters) {
        this.counterCharacterStyle = newVal.length >= this.counterCharacters ? 'counter-characters' : '';
      }
    },
  },
  computed: {
    statusInput() {
      return {
        disabled: this.disabled,
        error: this.error,
        'read-only': this.readonly,
      };
    },
  },
};
</script>
